<template>
  <CRow>
    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <CIcon name="cil-object-ungroup" />
          <h5 class="d-inline ml-2">
            Menu Sjabloons
          </h5>
          <div class="card-header-actions">
            <CButton v-if="restaurantId" color="link" :to="{name: 'View Restaurant', params: {'id': restaurantId}}" class="ml-2">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back To Restaurant
            </CButton>

            <CButton color="primary" :to="{ name: 'Add New Menu Sjabloon', params: {'restaurant_id': restaurantId}}">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Menu Sjabloon
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";

export default {
  name: "MenuSjabloons",
  components: {
    BackendTable,
  },
  computed: {
    restaurantId() { return this.$route.params.restaurant_id; },
  },
};
</script>
