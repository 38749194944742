<template>
  <CSidebar
    aside
    :show="$store.state.menuSjabloonMakeLinkAsideShow"
    @update:show="(val) => $store.commit('set', ['menuSjabloonMakeLinkAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <div class="d-flex my-1">
      <div class="flex-grow-1" v-if='!showErrorAlert'>
        <div class="logo-container" v-if="typeof item.id !== 'undefined' && item.restaurant.restaurant_logo">
          <CLink
            :to="{name: 'View Restaurant',params: { id: item.restaurant.id }}"
            target="_blank"
          >
            <CImg
                :src="item.restaurant.restaurant_logo_path"
                class="rounded mx-auto restaurant-logo"
                placeholderColor="lightgray"
            />
          </CLink>
        </div>
      </div>

      <div style="height: 56px">
        <CSidebarClose @click.native="$store.commit('toggle', 'menuSjabloonMakeLinkAsideShow')" />
      </div>
    </div>


    <!-- Error Alert -->
    <CListGroup class="list-group-accent mt-5" v-if='showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
        Error
      </CListGroupItem>

      <CListGroupItem class="list-group-item-accent-danger list-group-item-divider">
        <div class="c-avatar float-left mr-2">
          <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
        </div>
        <div>{{ errorAlertMessage }}</div>
      </CListGroupItem>
    </CListGroup>


    <CListGroup class="list-group-accent" v-if='!showErrorAlert && !loading'>

      <CListGroupItem class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        <CLink
          v-if="typeof item.id !== 'undefined'"
          :to="{name: 'View Restaurant',params: { id: item.restaurant.id }}"
          target="_blank"
          v-c-tooltip="{content: this.$router.resolve({name: 'View Restaurant',params: { id: item.restaurant.id }}).href }"
        >
          {{ item.restaurant.restaurant_name }} <CIcon class="ml-2 mb-1" name="cil-external-link" />
        </CLink>
      </CListGroupItem>

      <!-- Success Alert -->
      <CListGroupItem class="list-group-item-accent-success table-success" v-if='successAlertMessage'>
        <div class="c-avatar float-left align-items-baseline h-100 mr-2">
          <CIcon name="cil-check-alt" size="xl" class="c-avatar-img text-success"/>
        </div>
        <div>{{ successAlertMessage }}</div>
      </CListGroupItem>

      <!-- Make Link -->
      <CListGroupItem 
        v-if="(typeof item.id) !== 'undefined'"
        class="list-group-item-accent-primary"
      >
        <div><h4>{{ item.name }}</h4></div>
        <CRow>
          <CCol col="12">
            <h6 class="text-muted mr-3">
              <CIcon name="cil-arrow-right" class="mr-2"/>
              {{ item.notes }}
            </h6>
          </CCol>

          <CCol col="12" class="border-shadow mt-3 p-3">
            <CForm
              @submit="makeLink"
            >
              <CInputCheckbox v-if="!loading && showchk_rmcurrlinksdisabledmenus"
                class="mb-3"
                label="Remove current links from excluded menus"
                :checked.sync="remove_all_links"
                custom
              />

              <CButton
                type="submit"
                color="primary"
                size="sm"
                shape="pill"
                :disabled="item.detail_ext_groups_count == 0"
              >
                <CIcon name="cil-link" class="mb-1"/>&nbsp;Make Link
              </CButton>
            </CForm>
          </CCol>
        </CRow>
      </CListGroupItem>

    </CListGroup>

    <CElementCover :opacity="0.2" v-show="loading"/>
  </CSidebar>
</template>

<script>

export default {
  name: "MakeLinkAside",
  props: ['id'],

  data() {
    return {
      loading: false,
      item: [],
      remove_all_links: false,

      // Alert işlemleri için
      successAlertMessage: null,
      errorAlertMessage: '',
      showErrorAlert: false,
      showchk_rmcurrlinksdisabledmenus :false,
    };
  },
  mounted() {

  },

  watch: {
    reloadParams() {
      this.remove_all_links = false;
      this.successAlertMessage = null;
      this.showErrorAlert = false;
      this.getValues();
    },
  },

  computed: {
    reloadParams() {
      return [this.id];
    },
  },

  methods: {
    getValues() {
      this.loading = true;  //reset
      this.showchk_rmcurrlinksdisabledmenus =false; //reset

      this.$axios
        .get(
          this.$backend.MENU_SJABLOONS.SHOW.replace("{id}", this.id)
        )
        .then((response) => {
          this.item = response.data;

          if(this.item.categories.some(c => c.pivot.disabled_menus != null && c.pivot.disabled_menus != "")){
            this.showchk_rmcurrlinksdisabledmenus=true;
          }
          
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data.message
          this.showErrorAlert = true
        })
        .finally(() => {
          this.loading = false;
        });
    },

    makeLink(e) {
      e.preventDefault();
      this.loading = true;
      this.$axios.post(this.$backend.MENU_SJABLOONS.LINK, { 'id':this.id, 'remove_all_links':this.remove_all_links })
        .then((response) => {
          this.successAlertMessage = response.data;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data.message
          this.showErrorAlert = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
};
</script>


<style scoped>
.border-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.logo-container {
  height: 100px;
}
.logo-container a {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.restaurant-logo {
  display: block;
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
}
</style>