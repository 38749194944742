<template>
  <CCardBody>
    <MakeLinkAside :id="itemId" />

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="d-flex flex-md-row flex-column" style="gap: 0.5rem;">
            <!-- Restaurant Filter -->
            <div class="flex-grow-1">
              <v-select
                class="v-select-filter"
                placeholder="Select restaurants.."
                v-model="selectedRestaurants"
                multiple
                :options="allRestaurants"
                @input="restaurantsFilter"
              />
            </div>

            <!-- Pagination -->
            <div v-if="pages > 1">
              <CPagination
                align="end"
                :dots='false'
                :pages="pages"
                :active-page.sync="activePage"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :table-filter="{
        label: 'Filter:',
        placeholder: '...',
        external: true,
        lazy: true
      }"
      :tableFilterValue.sync="search"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No menu sjabloons found!',
      }"
      @update:sorter-value="sorterValue"
      @update:table-filter-value="tableSearch"
      @pagination-change="paginationChange"
    >

      <!-- Check Box -->
      <template #select="{item}">
        <td>
          <CInputCheckbox
            :checked="item._selected"
            @update:checked="() => check(item)"
            custom
          />
        </td>
      </template>

      <!-- ID -->
      <template #id="{item}">
        <td>
          <CLink :to="{ name: 'Edit Menu Sjabloon', params: { restaurant_id: item.restaurant_id, id: item.id }}">
            <strong>{{ item.id }}</strong>
          </CLink>
        </td>
      </template>

      <!-- Make Link -->
      <template #make_link="{item}">
        <td class="text-center">
          <CLink @click="showMakeLinkAside(item.id)">
            🔗 Make Link
          </CLink>
        </td>
      </template>

      <!-- Restaurant Name -->
      <template #restaurant_name="{item}">
        <td>
          <CLink :to="{name: 'View Restaurant',params: { id: item.restaurant_id }}">
            {{ item.restaurant.restaurant_name }}
          </CLink>
        </td>
      </template>

      <!-- Restaurant Location -->
      <template #location="{item}">
        <td>
          {{ item.restaurant.restaurant_postcode }}, {{ item.restaurant.restaurant_city }}
        </td>
      </template>

      <!-- Menu Sjabloon Categories -->
      <template #categories="{item}">
        <td>
          <span v-for="c in item.categories" :key="c.id" class="badge-category table-info">{{c.category_name}}</span>
        </td>
      </template>

      <!-- Menu Sjabloon -->
      <template #menu_sjabloon="{item}">
        <td>
          <CLink :to="{ name: 'Edit Menu Sjabloon', params: { restaurant_id: item.restaurant_id, id: item.id }}">
            <strong>{{ item.name }}</strong> <em>[{{ item.notes }}]</em>
            (<strong>{{ item.detail_ext_groups_count }}</strong> {{ 'menugroup' | pluralize(item.detail_ext_groups_count) }})
          </CLink>
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="py-2 text-center">
          <CButton
            size="sm"
            color="success"
            shape="pill"
            variant="outline"
            v-c-tooltip="'Make a copy'"
            @click="copyItem(item, index)"
            ><CIcon name="cil-copy" /></CButton
          >

          <CButton
            size="sm"
            color="info"
            shape="pill"
            variant="ghost"
            class="mx-1"
            :to="{ name: 'Edit Menu Sjabloon', params: { restaurant_id: item.restaurant_id, id: item.id }}"
            ><CIcon name="cil-align-left" /></CButton
          >

          <CButton
            size="sm"
            color="danger"
            shape="pill"
            variant="ghost"
            @click="deleteItem(item, index)"
            ><CIcon name="cil-trash" /></CButton
          >
        </td>
      </template>
    </CDataTable>

    <CModal class="replicate-menu-sjabloon"
      title="Replicate Menu Sjabloon"
      color="success"
      :show.sync="replicateMenuSjabloonModal"
      :closeOnBackdrop="false"
      centered
    >
      <div class="form-group">
        <label class="d-block" style="font-weight: 500">Target Restaurant:</label>
        <v-select
          class="v-select-filter"
          placeholder="Select restaurant.."
          v-model="selectedRestaurant"
          :options="allRestaurants"
        />
      </div>

      <template #footer class="p-1">
        <CButton @click="replicateMenuSjabloonModal = false" color="link">Cancel</CButton>
        <CButton @click="replicateMenuSjabloon" :disabled="selectedRestaurant === null" size="sm" color="success">
          <CIcon name="cil-clone" /> Replicate
        </CButton>
      </template>
    </CModal>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div>
            <CButton
              @click="replicateMenuSjabloonModal = true"
              color="success"
              size="sm"
              :disabled="selectedItem === null"
            >
              <CIcon name="cil-clone" class="mb-1"/>&nbsp;Replicate Menu Sjabloon
            </CButton>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

  </CCardBody>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import MakeLinkAside from './MakeLinkAside'

export default {
  name: "BackendTable",
  components: {
    vSelect,
    MakeLinkAside
  },
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "select",                    label: "",                  filter: false, sorter: false, _style: "width: 1%" },
        { key: "id",                        label: "ID#",               filter: false, _style: "width: 64px;" },
        { key: "restaurant_id",             label: "Rest. ID#",         filter: false, _style: "width: 92px;" },
        { key: "restaurant_name",           label: "Restaurant Name",   filter: false, _style: "min-width: 200px;" },
        { key: "location",                  label: "Location",          filter: false, _style: "width: 180px;" },
        { key: "categories",                label: "Categories",        filter: false, sorter: false, _style: "min-width: 200px;" },
        { key: "menu_sjabloon",             label: "Menu Sjabloon",     filter: false, _style: "min-width: 200px;" },
        { key: "make_link",                 label: "🔗",                filter: false, _style: "min-width: 128px; text-align: center" },
        { key: "actions",                   label: "Actions",           filter: false, _style: "min-width: 128px; text-align: center;" }
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Restaurants Filter işlemleri için
      allRestaurants: [],
      selectedRestaurants: [],
      selectedRestaurantsFilter: [],

      // Make Link Aside için
      itemId: 0,

      // Menu Sjabloon replicate (clone) işlemleri için
      selectedItem: null,
      replicateMenuSjabloonModal: false,
      selectedRestaurant: null
    };
  },

  created (){
    if(this.restaurantId)
      this.selectedRestaurantsFilter.push(this.restaurantId);
  },

  async mounted() {
    this.$store.state.menuSjabloonMakeLinkAsideShow = false;

    this.activePage =  this.queryPage;
    await Promise.all(
      [this.getAllRestaurants(), this.getAllItems()]
    );
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: this.currentRouteName, query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    currentRouteName () { return this.$route.name },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
    restaurantId () { return this.$route.params.restaurant_id },
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.MENU_SJABLOONS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          filter: this.selectedRestaurantsFilter
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            Object.keys(items.data).map(function(key, index) {
              let item = items.data[index];
              item._classes = '';
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    tableSearch(search) {
      // this.search = search;
      this.getAllItems();
    },
    check (item) {
      this.$set(item, "_selected", !item._selected);
      // v3
      if (item._selected) {
        // Sadece bir adet item seçilsin.
        if(this.selectedItem) {
          this.selectedItem._selected = false;
          this.selectedItem._classes = this.selectedItem._classes.replace(" table-selected", "");
        }

        item._classes = item._classes.concat(" table-selected");
        this.selectedItem = item;
        this.selectedRestaurant = this.allRestaurants.find(r => r.value == item.restaurant_id);
      } else {
        item._classes = item._classes.replace(" table-selected", "");
        this.selectedItem = null;
      }
    },


    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios
              .delete(
                this.$backend.MENU_SJABLOONS.DELETE.replace("{id}", item.id)
              )
              .then((response) => {
                this.$delete(this.loadedItems, index);
                this.showErrorAlert = false
                this.successAlertMessage = {itemName: item.name, message: 'menu sjabloon deleted.'}
                this.dismissSuccessAlert = this.dismissSecs
                this.total--
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data
                this.showErrorAlert = true
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

    copyItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to make a copy?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios
              .post(
                this.$backend.MENU_SJABLOONS.REPLICATE, { 'id': item.id }
              )
              .then((response) => {
                this.getAllItems();
                this.showErrorAlert = false
                this.successAlertMessage = { itemName: 'Success:', message: response.data.success };
                this.dismissSuccessAlert = this.dismissSecs
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data
                this.showErrorAlert = true
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

    replicateMenuSjabloon() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to make a copy?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.replicateMenuSjabloonModal = false;
            this.loading = true;
            this.$axios.post(this.$backend.MENU_SJABLOONS.REPLICATE, {
              'id':this.selectedItem.id,
              'target':this.selectedRestaurant.value
            })
              .then((response) => {
                this.getAllItems();
                this.showErrorAlert = false
                this.successAlertMessage = { itemName: 'Success:', message: response.data.success };
                this.dismissSuccessAlert = this.dismissSecs
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data
                this.showErrorAlert = true
              })
              .finally(() => {
                this.loading = false;
                window.scrollTo(0,0);
                this.selectedItem = null
                this.selectedRestaurant = null
              });
            // ---------------
          }
        },
      });
    },

    showMakeLinkAside(item_id) {
      this.itemId = item_id;
      this.$store.commit('toggle', 'menuSjabloonMakeLinkAsideShow');
    },

    // Filters
    async getAllRestaurants() {
      await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.allRestaurants = response.data;

          if(this.restaurantId) {
            this.selectedRestaurants = this.allRestaurants.find(item => item.value == this.restaurantId);
          }
        })
        .catch(() => { this.allRestaurants = [] })
    },

    restaurantsFilter() {
      this.selectedItem = null;

      this.selectedRestaurantsFilter = this.selectedRestaurants.map(function(obj) {
        return obj.value;
      });
      this.getAllItems();
    },

    resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedRestaurants = null;
        this.selectedRestaurantsFilter = null;
        this.selectedItem = null;
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>

<style>
.table th, .table td {
    padding: 0.5rem 0.25rem;
    vertical-align: middle;
}
.v-select-style .vs__search {
  line-height: 1.7;
}
.badge-category {
  padding: 0 3px;
  margin-right: 3px;
  border-radius: 3px;
}
</style>

<style scoped>
.replicate-menu-sjabloon .modal-header {
  padding: 0.75rem !important;
}
.replicate-menu-sjabloon .modal-footer {
  padding: 0.25rem !important;
}
</style>
